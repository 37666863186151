import React, { useEffect } from 'react';
import {
  HomePageRecentlyVisited,
  HomePageTopVisited,
  WelcomeTitle,
  CustomHomepageGrid,
  HeaderWorldClock,
  ClockConfig,
} from '@backstage/plugin-home';
import { Content, Page, Header } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';
import { HomePageCalendar } from '@backstage-community/plugin-gcalendar';
import logo from './logo.png';
import { UsefulLinksCard } from '@internal/backstage-plugin-useful-links';
import { DeveloperToolsCard } from '@internal/backstage-plugin-developer-tools';
import { HomePageYourOpenPullRequestsCard } from '@roadiehq/backstage-plugin-github-pull-requests';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  card: {
    width: '100%',
    background: '#424242',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
}));

const useLogoStyles = makeStyles(() => ({
  container: {
    margin: 0,
    textAlign: 'center',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
  },
}));

const clockConfigs: ClockConfig[] = [
  {
    label: 'CET',
    timeZone: 'Africa/Lagos',
  },
  {
    label: 'GMT',
    timeZone: 'Greenwich',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const defaultConfig = [
  {
    component: 'HomePageCalendar',
    x: 0,
    y: 0,
    width: 4,
    height: 8,
  },
  {
    component: 'DeveloperToolsCard',
    x: 4,
    y: 0,
    width: 8,
    height: 4,
  },
  {
    component: <HomePageYourOpenPullRequestsCard />,
    x: 5,
    y: 4,
    width: 8,
    height: 4,
  },
  {
    component: 'UsefulLinksCard',
    x: 0,
    y: 8,
    width: 4,
    height: 5,
  },
  {
    component: 'HomePageTopVisited',
    x: 4,
    y: 8,
    width: 4,
    height: 5,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 8,
    y: 8,
    width: 4,
    height: 5,
  },
];

const versionKey = 'homepage-layout-version';
const currentVersion = 'v2'; // Bump this version when you introduce a new default layout for all users
const layoutStorageKey = '/home.customHomepage/home';

export const HomePage = () => {
  const classes = useStyles();
  const logoClasses = useLogoStyles();
  const languages = ['English', 'Spanish'];

  useEffect(() => {
    const savedVersion = localStorage.getItem(versionKey);
    if (savedVersion !== currentVersion) {
      localStorage.removeItem(layoutStorageKey);
      localStorage.setItem(versionKey, currentVersion);
    }
  }, []);

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={<WelcomeTitle language={languages}/>} pageTitleOverride="Home">
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NewAnnouncementBanner />
            </Grid>
            <Grid item xs={12} className={logoClasses.container}>
              <img src={logo} alt="Company Logo" className={logoClasses.logo} />
            </Grid>
            <Grid item xs={12}>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline } }}
                placeholder="Search for Squads, People, APIs, Services, Libraries, Systems, Documentation, ADRs and more ..."
              />
            </Grid>
            <Grid item xs={12}>
              <CustomHomepageGrid config={defaultConfig}>
                <HomePageCalendar />
                <AnnouncementsCard max={3} />
                <HomePageTopVisited />
                <HomePageRecentlyVisited />
                <HomePageYourOpenPullRequestsCard />
                <UsefulLinksCard />
                <DeveloperToolsCard/>
              </CustomHomepageGrid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};