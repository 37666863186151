import React from "react";
import { Route } from "react-router-dom";
import { apiDocsPlugin, ApiExplorerPage } from "@backstage/plugin-api-docs";
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from "@backstage/plugin-catalog";
import { catalogImportPlugin } from "@backstage/plugin-catalog-import";
import { ScaffolderPage, scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { SearchPage } from "@backstage/plugin-search";
import { TechRadarPage } from "@backstage-community/plugin-tech-radar";
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from "@backstage/plugin-techdocs";
import { UserSettingsPage } from "@backstage/plugin-user-settings";
import { apis } from "./apis";
import { entityPage } from "./components/catalog/EntityPage";
import { searchPage } from "./components/search/SearchPage";
import { Root } from "./components/Root";
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from "@backstage/core-components";
import { createApp } from "@backstage/app-defaults";
import { AppRouter, FlatRoutes } from "@backstage/core-app-api";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { VisitListener } from '@backstage/plugin-home';
import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';

const app = createApp({
  apis,
  plugins: [shortcutsPlugin],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'google-auth-provider',
          title: 'Google',
          message: 'Sign in using Google',
          apiRef:  googleAuthApiRef,
        }}
      />),
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar/platform"
      element={<TechRadarPage width={1500} height={800} id={"platform"} pageTitle={"Tech-Radar"} title="Platform" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route
      path="/tech-radar/mobility"
      element={<TechRadarPage width={1500} height={800} id={"mobility"} pageTitle={"Tech-Radar"} title="Mobility" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route
      path="/tech-radar/delivery"
      element={<TechRadarPage width={1500} height={800} id={"delivery"} pageTitle={"Tech-Radar"} title="Delivery" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route
      path="tech-radar/financial-services"
      element={<TechRadarPage width={1500} height={800} id={"financial-Services"} pageTitle={"Tech-Radar"} title="Financial-Services" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route
      path="/tech-radar/shared-services"
      element={<TechRadarPage width={1500} height={800} id={"shared-Services"} pageTitle={"Tech-Radar"} title="Shared-Services" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route
      path="/tech-radar/ml-ai"
      element={<TechRadarPage width={1500} height={800} id={"ml-ai"} pageTitle={"Tech-Radar"} title="ML and AI" subtitle={"Explore and pick the recommended technologies from your domain."} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);