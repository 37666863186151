import '@backstage/cli/asset-types';
import React from 'react';
import App from './App';
import * as Sentry from "@sentry/react";
import * as ReactDOM from 'react-dom/client';

Sentry.init({
  debug: true,
  dsn: 'https://26359c129600f4bdfc777bcb3842dc4d@o4505873727291392.ingest.us.sentry.io/4506944131760128',
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/backstage\.yplatform.dev/,/^https:\/\/backstage-stg\.yplatform.dev/],
      instrumentNavigation: true,
      instrumentPageLoad: true
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
