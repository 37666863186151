import {
  RadarEntry,
  TechRadarApi,
  TechRadarLoaderResponse,
} from "@backstage-community/plugin-tech-radar";
import { Config } from "@backstage/config";
import { IdentityApi } from "@backstage/core-plugin-api";

const populateQuadrant = function (quadrantName: string, data: any): Array<RadarEntry> {
  const entries = new Array<RadarEntry>();

  let q: keyof typeof data;
  for (q in data) {
    const quadrant = data[q];
    let r: keyof typeof quadrant;

    for (r in quadrant) {
      entries.push({
        timeline: [
          {
            moved: 0,
            ringId: q,
            date: new Date("2020-08-06"),
          },
        ],
        key: quadrant[r],
        id: quadrant[r],
        title: quadrant[r],
        quadrant: quadrantName,
      });
    }
  }

  return entries;
};

export class TechRadarClient implements TechRadarApi {
  backendBaseUrl: string;
  authToken: string | null = null;

  constructor(config: Config, identityApi: IdentityApi) {
    this.backendBaseUrl = config.getString('backend.baseUrl');
    identityApi.getCredentials().then(credentials => {
      if (credentials.token) {
        this.authToken = credentials.token;
      }
    });
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    if (!id) {
      throw new Error('Domain ID is required');
    }
    const baseUrl = `${this.backendBaseUrl}/api/tech-radar?domain=${id}`;

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (this.authToken) {
      headers['Authorization'] = `Bearer ${this.authToken}`;
    }

    const response = await fetch(baseUrl, { headers });
    if (response.status !== 200) {
      throw new Error(`Could not fetch tech-radar data for this domain :(.
        Make sure that it is properly populated in the cross-developers-platform repository or contact #devx-squad for more support.`);
    }

    const data = await response.json();

    const rings = [
      { id: "adopt", name: "ADOPT", color: "#5BA300" },
      { id: "trial", name: "TRIAL", color: "#009EB0" },
      { id: "assess", name: "ASSESS", color: "#C7BA00" },
      { id: "hold", name: "HOLD", color: "#E09B96" },
    ];

    const quadrants = [
      { id: "infrastructure", name: "Infrastructure" },
      { id: "frameworks", name: "Frameworks" },
      { id: "languages", name: "Languages" },
      { id: "process", name: "Process" },
    ];

    const entries = await Promise.all(data.entries.map(async (entry: any) => {
      return populateQuadrant(entry.quadrant, entry.data);
    }));

    return {
      entries: entries.flat(),
      quadrants,
      rings,
    };
  }
}