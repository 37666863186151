import {
  createApiFactory,
  createPlugin,
  createReactExtension,
} from "@backstage/core-plugin-api";
import { developerToolsApiRef } from "../src/api/DeveloperToolsApi";
import { DeveloperToolsApiImpl } from "../src/api/DeveloperToolsApiImpl";

export const developerToolsPlugin = createPlugin({
  id: "developer-tools",
});

export const DeveloperToolsCard = developerToolsPlugin.provide(
  createReactExtension({
    name: "DeveloperToolsCard",
    component: {
      lazy: () =>
        import("./components/DeveloperToolsCard").then(
          (m) => m.DeveloperToolsComponent,
        ),
    },
    data: {
      "home.widget.config": {
        title: "Developer Tools",
        description: "A card with developer tools",
      },
    },
  }),
);

export const apis = [
  createApiFactory({
    api: developerToolsApiRef,
    deps: {},
    factory: () => new DeveloperToolsApiImpl(),
  }),
];
