import { GoogleAnalytics4 } from "@backstage-community/plugin-analytics-module-ga4";
import {
  GCalendarApiClient,
  gcalendarApiRef,
} from "@backstage-community/plugin-gcalendar";
import { techRadarApiRef } from "@backstage-community/plugin-tech-radar";
import {
  AnyApiFactory,
  analyticsApiRef,
  configApiRef,
  createApiFactory,
  fetchApiRef,
  googleAuthApiRef,
  identityApiRef,
  storageApiRef,
} from "@backstage/core-plugin-api";
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from "@backstage/integration-react";
import { VisitsStorageApi, visitsApiRef } from "@backstage/plugin-home";
import {
  DeveloperToolsApiImpl,
  developerToolsApiRef,
} from "@internal/backstage-plugin-developer-tools";
import { TechRadarClient } from "./components/techradar/TechRadarClient";

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: techRadarApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      new TechRadarClient(configApi, identityApi),
  }),
  // Instantiate and register the GA Analytics API Implementation.
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
  // Implementation that relies on a provided storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  createApiFactory({
    api: gcalendarApiRef,
    deps: { authApi: googleAuthApiRef, fetchApi: fetchApiRef },
    factory: (deps) => new GCalendarApiClient(deps),
  }),
  createApiFactory({
    api: developerToolsApiRef,
    deps: {},
    factory: () => new DeveloperToolsApiImpl(),
  }),
];
