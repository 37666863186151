import { createApiRef } from "@backstage/core-plugin-api";

// API reference
export const developerToolsApiRef = createApiRef<DeveloperToolsApi>({
  id: "plugin.developer-tools.api",
});

// API interface
export interface DeveloperToolsApi {
  getToolLinks(): Promise<ToolLink[]>;
  saveToolLinks(toolLinks: ToolLink[]): Promise<void>;
}

// Struct of a tool link
export interface ToolLink {
  name: string;
  href: string;
  logo: string;
  alt: string;
}
