import { createPlugin, createReactExtension } from "@backstage/core-plugin-api";

export const usefulLinksPlugin = createPlugin({
  id: "useful-links",
});

export const UsefulLinksCard = usefulLinksPlugin.provide(
  createReactExtension({
    name: "UsefulLinksCard",
    component: {
      lazy: () =>
        import("./components/UsefulLinksCard").then(
          (m) => m.UsefulLinksComponent,
        ),
    },
    data: {
      "home.widget.config": {
        title: "Useful Links",
        description: "A card with useful links",
      },
    },
  }),
);
