import {
  DevlakeLogo,
  GitHubLogo,
  JiraLogo,
  NotionLogo,
  PlantUMLLogo,
  SentryLogo,
  SonarQubeLogo,
  StructurizrLogo,
  VaultwardenLogo,
} from "../public/logos";
import { DeveloperToolsApi, ToolLink } from "./DeveloperToolsApi";

const TOOL_LINKS_STORAGE_KEY = "developerToolsToolLinks";

const defaultToolLinks: ToolLink[] = [
  {
    name: "Jira",
    href: "https://yassir.atlassian.net",
    logo: JiraLogo,
    alt: "Jira",
  },
  {
    name: "GitHub",
    href: "https://github.com/YAtechnologies",
    logo: GitHubLogo,
    alt: "GitHub",
  },
  {
    name: "Notion",
    href: "https://www.notion.so/ysir",
    logo: NotionLogo,
    alt: "Notion",
  },
  {
    name: "Sentry",
    href: "https://yassir-inc.sentry.io",
    logo: SentryLogo,
    alt: "Sentry",
  },
  {
    name: "Devlake",
    href: "https://devlake.yplatform.dev",
    logo: DevlakeLogo,
    alt: "Devlake",
  },
  {
    name: "SonarQube",
    href: "https://sonar.yplatform.dev",
    logo: SonarQubeLogo,
    alt: "SonarQube",
  },
  {
    name: "Vaultwarden",
    href: "https://vaultwarden.yplatform.dev",
    logo: VaultwardenLogo,
    alt: "Vaultwarden",
  },
  {
    name: "Structurizr",
    href: "https://structurizr.yplatform.dev",
    logo: StructurizrLogo,
    alt: "Structurizr",
  },
  {
    name: "PlantUML",
    href: "https://plant-uml.yplatform.dev",
    logo: PlantUMLLogo,
    alt: "PlantUML",
  },
];

export class DeveloperToolsApiImpl implements DeveloperToolsApi {
  async getToolLinks(): Promise<ToolLink[]> {
    const storedLinks = localStorage.getItem(TOOL_LINKS_STORAGE_KEY);
    if (storedLinks) {
      return JSON.parse(storedLinks);
    } else {
      // Initialize localStorage with default tool links if none exist
      localStorage.setItem(
        TOOL_LINKS_STORAGE_KEY,
        JSON.stringify(defaultToolLinks),
      );
      return defaultToolLinks;
    }
  }

  async saveToolLinks(toolLinks: ToolLink[]): Promise<void> {
    localStorage.setItem(TOOL_LINKS_STORAGE_KEY, JSON.stringify(toolLinks));
  }
}
