import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/Home";
import ExtensionIcon from "@material-ui/icons/Extension";
import MapIcon from "@material-ui/icons/MyLocation";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BuildIcon from '@material-ui/icons/Build';
import StorageIcon from '@material-ui/icons/Storage';
import CodeIcon from '@material-ui/icons/Code';
import WebIcon from '@material-ui/icons/Web';
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import DomainIcon from '@material-ui/icons/Domain';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LogoFull from "./LogoFull";
import LogoIcon from "./LogoIcon";
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from "@backstage/plugin-user-settings";
import { SidebarSearchModal } from "@backstage/plugin-search";
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenuItem,
  SidebarSubmenu,
  SidebarExpandButton,
} from "@backstage/core-components";
import { MyGroupsSidebarItem } from "@backstage/plugin-org";
import { Shortcuts } from '@backstage-community/plugin-shortcuts';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar disableExpandOnHover>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeOutlinedIcon} to="/" text="Home" />
        <SidebarItem icon={AnnouncementIcon} to="announcements" text="Announcements" />
        <SidebarItem icon={MenuBookIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system&filters[user]=all"
              icon={DeviceHubIcon}
            />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource&filters[user]=all"
              icon={CategoryIcon}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component&filters[user]=all"
              icon={ExtensionIcon}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Services"
              to="catalog?filters[kind]=component&filters[type]=service&filters[user]=all"
              icon={BuildIcon}
            />
            <SidebarSubmenuItem
              title="Infra"
              to="catalog?filters[kind]=component&filters[type]=infra&filters[user]=all"
              icon={StorageIcon}
            />
            <SidebarSubmenuItem
              title="Libraries"
              to="catalog?filters[kind]=component&filters[type]=library&filters[user]=all"
              icon={CodeIcon}
            />
            <SidebarSubmenuItem
              title="Modules"
              to="catalog?filters[kind]=component&filters[type]=module&filters[user]=all"
              icon={ClassIcon}
            />
            <SidebarSubmenuItem
              title="Websites"
              to="catalog?filters[kind]=component&filters[type]=website&filters[user]=all"
              icon={WebIcon}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=group&filters[type]=domain&filters[user]=all"
              icon={DomainIcon}
            />
            <SidebarSubmenuItem
              title="Teams"
              to="catalog?filters[kind]=group&filters[type]=team&filters[user]=all"
              icon={SupervisorAccountIcon}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user&filters[user]=all"
              icon={PersonIcon}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <MyGroupsSidebarItem
          singularTitle="My Squad"
          pluralTitle="My Squads"
          icon={GroupIcon}
        />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        {/* <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." /> */}
        {/* End global nav */}
        <SidebarDivider />
        {/* Tech radar menu with sub items: One per domain */}
        <SidebarItem icon={MapIcon} text="Tech-Radars">
          <SidebarSubmenu>
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/platform" title="Platform" />
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/mobility" title="Mobility" />
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/delivery" title="Delivery" />
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/financial-services" title="Financial-Services" />
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/shared-services" title="Shared-Services" />
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar/ml-ai" title="ML-AI" />
          </SidebarSubmenu>
        </SidebarItem>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <Shortcuts allowExternalLinks />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
      <SidebarExpandButton />
    </Sidebar>
    {children}
  </SidebarPage>
);